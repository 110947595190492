import * as types from './mutation-types'
import Fetch from '../lib/fetch'

export default {
  // 获取jsapi_ticket
  async [types.REQUEST_JSAPI_TICKET] (context, params) {
    // context.commit(types.REQUEST_JSAPI_TICKET)
    try {
      let res = await Fetch.request('/jsapi-ticket')
      if (res.data) {
        context.commit(types.JSAPI_TICKET_SUCCESS, res.data)
      } else {
        context.commit(types.JSAPI_TICKET_FAIL, res.errmsg || '未知错误')
      }
    } catch (err) {
      context.commit(types.JSAPI_TICKET_FAIL, err.message || '未知错误')
    }
  },

  // 获取用户信息
  async [types.REQUEST_PROFILE] (context, params) {
    context.commit(types.REQUEST_PROFILE)
    try {
      let res = await Fetch.request('/user-info', params, 'post')
      if (res.data) {
        context.commit(types.PROFILE_SUCCESS, res.data)
      } else {
        if (params.mode === 'index') {
          context.commit(types.PROFILE_FAIL, res.errmsg || '未知错误')
        } else {
          context.commit(types.PROFILE_FAIL, '')
          alert('错误：' + res.errmsg)
        }
      }
    } catch (err) {
      if (params.mode === 'index') {
        context.commit(types.PROFILE_FAIL, err.message || '未知错误')
      } else {
        context.commit(types.PROFILE_FAIL, '')
      }
    }
  },

  // 获取手机对应的膜信息
  async [types.REQUEST_DEVICE_ITEMS] (context, params) {
    context.commit(types.REQUEST_DEVICE_ITEMS)
    try {
      // let res = await Fetch.request('/device-items', params)
      let res = await Fetch.request('/equipment-device-items', params)
      if (res.data) {
        context.commit(types.DEVICE_ITEMS_SUCCESS, res.data)
      } else {
        context.commit(types.DEVICE_ITEMS_FAIL, res.errmsg || '未知错误')
      }
    } catch (err) {
      context.commit(types.DEVICE_ITEMS_FAIL, err.message || '未知错误')
    }
  },

  // 获取订单信息
  async [types.REQUEST_ORDERS] (context, params) {
    context.commit(types.REQUEST_ORDERS)
    try {
      let res = await Fetch.request('/sale-orders', params)
      if (res.data) {
        context.commit(types.ORDERS_SUCCESS, res.data)
      } else {
        context.commit(types.ORDERS_FAIL, res.errmsg || '未知错误')
      }
    } catch (err) {
      context.commit(types.ORDERS_FAIL, err.message || '未知错误')
    }
  },

  // 发起支付
  async [types.REQUEST_PAYMENT] (context, params) {
    context.commit(types.REQUEST_PAYMENT)
    try {
      let res = await Fetch.request('/pay', params, 'post')
      if (res.data) {
        context.commit(types.REQUEST_PAYMENT_SUCCESS, res.data)
      } else {
        context.commit(types.REQUEST_PAYMENT_FAIL, res || '未知错误')
      }
    } catch (err) {
      context.commit(types.REQUEST_PAYMENT_FAIL, err.message || '未知错误')
    }
  },

  // 取消支付
  async [types.CANCEL_PAYMENT] (context, params) {
    context.commit(types.CANCEL_PAYMENT)
    try {
      let res = await Fetch.request('/cancel-pay', params, 'post')
      if (res.data) {
        context.commit(types.CANCEL_PAYMENT_SUCCESS, res.data)
      } else {
        context.commit(types.CANCEL_PAYMENT_FAIL, res || '未知错误')
      }
    } catch (err) {
      context.commit(types.CANCEL_PAYMENT_FAIL, err.message || '未知错误')
    }
  },

  // 获取订单信息
  async [types.REQUEST_ORDER_INFO] (context, params) {
    context.commit(types.REQUEST_ORDER_INFO)
    try {
      let res = await Fetch.request('/order-info', params)
      if (res.data) {
        context.commit(types.ORDER_INFO_SUCCESS, res.data)
      } else {
        context.commit(types.ORDER_INFO_FAIL, res.errmsg || '未知错误')
      }
    } catch (err) {
      context.commit(types.ORDER_INFO_FAIL, err.message || '未知错误')
    }
  },

  // 获取兑换码信息
  async [types.REQUEST_COUPON_INFO] (context, params) {
    context.commit(types.REQUEST_COUPON_INFO)
    try {
      let res = await Fetch.request('/coupon-info', params,'post')
      if (res.data) {
        context.commit(types.COUPON_INFO_SUCCESS, res.data)
      } else {
        context.commit(types.COUPON_INFO_FAIL, res.errmsg || '未知错误')
      }
    } catch (err) {
      context.commit(types.COUPON_INFO_FAIL, err.message || '未知错误')
    }
  },

  // 获取配置文件信息
  async [types.REQUEST_SYSTEM_PROFILES] (context,params) {
    context.commit(types.REQUEST_SYSTEM_PROFILES)
      try {
        let res = await Fetch.request('/system-profiles',params)
      if (res.data) {
        context.commit(types.SYSTEM_PROFILES_SUCCESS, res.data)
      } else {
        context.commit(types.SYSTEM_PROFILES_FAIL, res.errmsg || '未知错误')
      }
      } catch (err) {
        context.commit(types.SYSTEM_PROFILES_FAIL, err.message || '未知错误')
      }
  },

  // 获取报价信息
  async [types.REQUEST_QUOTATION_INFO] (context,params) {
    context.commit(types.REQUEST_QUOTATION_INFO)
      try {
        let res = await Fetch.request('/quotation-info',params)
      if (res.data) {
        context.commit(types.QUOTATION_INFO_SUCCESS, res.data)
      } else {
        context.commit(types.QUOTATION_INFO_FAIL, res.errmsg || '未知错误')
      }
      } catch (err) {
        context.commit(types.QUOTATION_INFO_FAIL, err.message || '未知错误')
      }
  }
}
