import axios from 'axios'
// import router from './router'
//const PREFIX = '/auto-screen-protect/api/v1/'
const PREFIX = '/auto-screen-protect/api/v2/'
class Fetch {
  static async request (url, params, method = 'get', headers = {}) {
    let token = localStorage.getItem('__user_token__')
    if (token) {
      headers['x-user-token'] = `${token}`
    }
    let requestConfig = {
      url: url,
      method: method,
      baseURL: PREFIX,
      headers
    }
    if (params) {
      if (method.toLowerCase() === 'get') {
        requestConfig.params = params
      } else if (method.toLowerCase() === 'post') {
        requestConfig.data = params
      }
    }
    //axios.defaults.headers.get['Content-Type'] = 'application/json';
    //axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    let result = await axios.request(requestConfig)
    if (result.data.errcode === 4003) {
      // router.push('/signin')
      // alert('Invalid UserToken！')
      alert('4003: '+'登录信息已过期，请重新登录！')
    }
    return result.data
  }
}
export default Fetch
