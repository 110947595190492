import Vue from 'vue';
// import VConsole from 'vconsole';
import App from './App.vue';
import router from './router';
import store from './store';
import util from './lib/util';

Vue.config.productionTip = false;
Vue.use(util);
// const vConsole = new VConsole();
// export default vConsole;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
