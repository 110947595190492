import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        // 移动端
        {
            path: '/index',
            name: 'Index',
            component: () =>
                import ('@/views/Index.vue')
        },
        // {
        //     path: '/index',
        //     redirect: '@/views/Pay.vue'
        // },
        {
            path: '/pay',
            name: 'Pay',
            component: () =>
                import ('@/views/Pay.vue')
        },
        {
            path: '/waiting',
            name: 'Waiting',
            component: () =>
                import ('@/views/Waiting.vue')
        },
        {
            path: '/pay-success',
            name: 'PaySuccess',
            component: () =>
                import ('@/views/PaySuccess.vue')
        },
        {
            path: '/pay-failure',
            name: 'PayFailure',
            component: () =>
                import ('@/views/PayFailure.vue')
        },
        {
            path: '/share',
            name: 'Share',
            component: () =>
                import ('@/views/Share.vue')
        }
    ]
})