import * as types from './mutation-types'

export default {

  // 获取手机对应的膜信息
  [types.REQUEST_DEVICE_ITEMS] (state, data) {
    state.DeviceItems.loading = true
  },

  [types.DEVICE_ITEMS_SUCCESS] (state, data) {
    state.DeviceItems.data = data
    state.DeviceItems.loading = false
  },

  [types.DEVICE_ITEMS_FAIL] (state, err) {
    state.DeviceItems.loading = false
  },

  // 获取微信JSAPI_TICKET
  [types.JSAPI_TICKET_SUCCESS] (state, data) {
    state.User.ticket = data.jsapi_ticket
  },

  [types.JSAPI_TICKET_FAIL] (state, err) {
    state.User.err = err
  },

  // 获取用户信息
  [types.REQUEST_PROFILE] (state, data) {
    state.User.loading = true
  },

  [types.PROFILE_SUCCESS] (state, data) {
    state.User.loading = false
    state.User.info = data.info
    state.User.token = data.userToken
  },

  [types.PROFILE_FAIL] (state, err) {
    state.User.loading = false
    state.User.err = err
  },

  // 获取订单信息
  [types.REQUEST_ORDERS] (state, data) {
    state.SaleOrder.loading = true
  },

  [types.ORDERS_SUCCESS] (state, data) {
    state.SaleOrder.data = data
    state.SaleOrder.loading = false
  },

  [types.ORDERS_FAIL] (state, err) {
    state.SaleOrder.loading = false
  },

  // 发起支付
  [types.REQUEST_PAYMENT] (state, data) {
    state.Payment.loading = true
    state.Payment.data = {}
    state.Payment.err = {}
  },

  [types.REQUEST_PAYMENT_SUCCESS] (state, data) {
    state.Payment.loading = false
    state.Payment.data = data
  },

  [types.REQUEST_PAYMENT_FAIL] (state, err) {
    state.Payment.loading = false
    state.Payment.err = err
  },

  // 取消支付
  [types.CANCEL_PAYMENT] (state, data) {
    state.User.loading = true
  },

  [types.CANCEL_PAYMENT_SUCCESS] (state, data) {
    state.User.loading = false
  },

  [types.CANCEL_PAYMENT_FAIL] (state, err) {
    state.User.loading = false
    state.User.err = err.errmsg
  },

  // 获取订单信息
  [types.REQUEST_ORDER_INFO] (state, data) {
    state.SaleOrder.status = ''
    state.SaleOrder.coupon_code = ''
  },

  [types.ORDER_INFO_SUCCESS] (state, data) {
    // state.SaleOrder.status = data
    state.SaleOrder.status = data.order_status,
    state.SaleOrder.coupon_code = data.coupon_code
  },

  [types.ORDER_INFO_FAIL] (state, err) {
    state.SaleOrder.err = err
  },
  // 获取兑换码信息
  [types.REQUEST_COUPON_INFO] (state, data) {
    // state.Coupon.coupon_remained_count = 0
    state.Coupon.data = {}
  },

  [types.COUPON_INFO_SUCCESS] (state, data) {
    state.Coupon.data.use_limit = data.use_limit;
    state.Coupon.data.coupon_remained_count = data.coupon_remained_count;
    state.Coupon.data.start_date = data.start_date;
    state.Coupon.data.end_date = data.end_date;
  },

  [types.COUPON_INFO_FAIL] (state, err) {
    state.Coupon.data = {}
    state.Coupon.err = err
  },

  // 获取配置文件信息
  [types.REQUEST_SYSTEM_PROFILES] (state, data) {
    state.SystemProfiles.data = ""
  },
  
  [types.SYSTEM_PROFILES_SUCCESS] (state, data) {
    state.SystemProfiles.data = data.system_profiles
  },
  
  [types.SYSTEM_PROFILES_FAIL] (state, err) {
    state.SystemProfiles.err = err
  },

  // 获取报价信息
  [types.REQUEST_QUOTATION_INFO] (state, data) {
      state.QUOTATION.data = ""
  },
    
  [types.QUOTATION_INFO_SUCCESS] (state, data) {
      state.QUOTATION.data = data
  },
    
  [types.QUOTATION_INFO_FAIL] (state, err) {
      state.QUOTATION.err = err
  }

}
