// 获取手机对应的膜信息
export const REQUEST_DEVICE_ITEMS = 'REQUEST_DEVICE_ITEMS'
export const DEVICE_ITEMS_SUCCESS = 'DEVICE_ITEMS_SUCCESS'
export const DEVICE_ITEMS_FAIL = 'DEVICE_ITEMS_FAIL'

// 微信jsApiTicket
export const REQUEST_JSAPI_TICKET = 'REQUEST_JSAPI_TICKET'
export const JSAPI_TICKET_SUCCESS = 'JSAPI_TICKET_SUCCESS'
export const JSAPI_TICKET_FAIL = 'JSAPI_TICKET_FAIL'

// 用户信息
export const REQUEST_PROFILE = 'REQUEST_PROFILE'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_FAIL = 'PROFILE_FAIL'

// 获取订单信息
export const REQUEST_ORDERS = 'REQUEST_ORDERS'
export const ORDERS_SUCCESS = 'ORDERS_SUCCESS'
export const ORDERS_FAIL = 'ORDERS_FAIL'

// 发起支付
export const REQUEST_PAYMENT = 'REQUEST_PAYMENT'
export const REQUEST_PAYMENT_SUCCESS = 'REQUEST_PAYMENT_SUCCESS'
export const REQUEST_PAYMENT_FAIL = 'REQUEST_PAYMENT_FAIL'

// 取消支付
export const CANCEL_PAYMENT = 'CANCEL_PAYMENT'
export const CANCEL_PAYMENT_SUCCESS = 'CANCEL_PAYMENT_SUCCESS'
export const CANCEL_PAYMENT_FAIL = 'CANCEL_PAYMENT_FAIL'

// 获取订单信息
export const REQUEST_ORDER_INFO = 'REQUEST_ORDER_INFO'
export const ORDER_INFO_SUCCESS = 'ORDER_INFO_SUCCESS'
export const ORDER_INFO_FAIL = 'ORDER_INFO_FAIL'

// 获取兑换码信息
export const REQUEST_COUPON_INFO = 'REQUEST_COUPON_INFO'
export const COUPON_INFO_SUCCESS = 'COUPON_INFO_SUCCESS'
export const COUPON_INFO_FAIL = 'COUPON_INFO_FAIL'

// 获取配置文件信息
export const REQUEST_SYSTEM_PROFILES = 'REQUEST_SYSTEM_PROFILES'
export const SYSTEM_PROFILES_SUCCESS = 'SYSTEM_PROFILES_SUCCESS'
export const SYSTEM_PROFILES_FAIL = 'SYSTEM_PROFILES_FAIL'

// 获取报价信息
export const REQUEST_QUOTATION_INFO = 'REQUEST_QUOTATION_INFO'
export const QUOTATION_INFO_SUCCESS = 'QUOTATION_INFO_SUCCESS'
export const QUOTATION_INFO_FAIL = 'QUOTATION_INFO_FAIL'