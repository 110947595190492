<template>
<div v-if="errmsg !== ''" id="app">
    <!-- <mobile-header></mobile-header> -->
    <div class="err-box">
      <i class="err-icon"></i>
      <span class="err-msg">{{errmsg}}</span>
    </div>
  </div>
  <div v-else id="app">
    <!-- <mobile-header></mobile-header> -->
    <!-- <div class="mask" v-if="User.loading">
      <loading></loading>
    </div> -->
    <transition name="page">
      <router-view/>
    </transition>
  </div>
</template>
<script>
// import Loading from '@/components/Loading';
// import MobileHeader from '@/components/MobileHeader';
export default {
  name: 'app',
  // components: {
  //   MobileHeader,
  //   Loading
  // },
  data: function () {
    return {
      client:'',
      errmsg:''
    }
  },
  computed: {
  },
  created: function () {
  },
  methods: {
  }
}
</script>
<style lang="scss">
@import '@/base.scss';

#app {
    .err-box{
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: 40px;
      overflow: hidden;
      text-align: center;
      .err-icon{
        display: inline-block;
        margin-top: 50px;
        height: 130px;
        width: 130px;
        background-image: url(./assets/mobile/error.png);
        background-size: 105%;
        background-repeat: no-repeat;
      }
      .err-msg{
        display: block;
        margin-top: 20px;
        padding: 0 20px;
        font-size: 18px;
        white-space: normal;
      }
    }
}
</style>
