const phoneReg = /^((13[0-9])|(14[5,7,9])|(15[^4])|(18[0-9])|(17[0,1,3,5,6,7,8]))\d{8}$/
import Fetch from './fetch'
// import { resolve } from 'path';
function goPath(path, method) {
    if (method === 'rp') {
        this.$router.replace(path)
    } else {
        this.$router.push(path)
    }
}

function goBack() {
    this.$router.go(-1)
}

function getQueryString (name) {
    // var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
    // var r = window.location.search.substr(1).match(reg)
    // if (r != null) return unescape(r[2]); return null
    // eslint-disable-next-line no-sparse-arrays
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
}

async function getProfileValue(profileCode){
    let profileValue = '';
    const res = await Fetch.request('/system-profile',{
        profileCode: profileCode
    });
    if (res.data) {
        profileValue = res.data.profile_value;
    }
    return profileValue;
}

async function getQuotationInfo(quotationCode){
    return await Fetch.request('/quotation-info',{
        quotationCode: quotationCode
    });
}

export function getUrlKey(name) {
    // eslint-disable-next-line no-sparse-arrays
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
}
export default {
    install(Vue) {
        Vue.prototype.$goPath = goPath;
        Vue.prototype.$goBack = goBack;
        Vue.prototype.$phoneReg = phoneReg;
        Vue.prototype.$getQueryString = getQueryString;
        Vue.prototype.$getProfileValue = getProfileValue;
        Vue.prototype.$getQuotationInfo = getQuotationInfo;
    }
}