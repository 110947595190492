var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.errmsg !== ""
    ? _c("div", { attrs: { id: "app" } }, [
        _c("div", { staticClass: "err-box" }, [
          _c("i", { staticClass: "err-icon" }),
          _c("span", { staticClass: "err-msg" }, [_vm._v(_vm._s(_vm.errmsg))])
        ])
      ])
    : _c(
        "div",
        { attrs: { id: "app" } },
        [_c("transition", { attrs: { name: "page" } }, [_c("router-view")], 1)],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }