import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import actions from './actions'
import mutations from './mutations'
Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'


export default new Vuex.Store({
  state: {
    DeviceItems: {
      data: {
        'device_id': '',
        'device_name': '',
        'device_thumb': '',
        'device_items': [
          {
            'brand_id': '',
            'brand_name': '',
            'items': [
              {
                'item_id': '',
                'item_name': '',
                'item_inventory': 0,
                'item_price': ''
              }
            ]
          }
        ]
        // 'device_id': '6s78a87gf78f89gj6fg89h6df89',
        // 'device_name': 'iPhone X',
        // 'device_thumb': 'https://img13.360buyimg.com/n7/jfs/t10675/253/1344769770/66891/92d54ca4/59df2e7fN86c99a27.jpg',
        // 'device_items': [
        //   {
        //     'brand_id': '8af4968e63714c620163714cdb490001',
        //     'brand_name': '天一',
        //     'items': [
        //       {
        //         'item_id': '1',
        //         'item_name': '6D磨沙全屏膜',
        //         'item_inventory': 10,
        //         'item_price': '28.00'
        //       }, {
        //         'item_id': '2',
        //         'item_name': '6D全屏膜',
        //         'item_inventory': 10,
        //         'item_price': '18.00'
        //       }
        //     ]
        //   },
        //   {
        //     'brand_id': '8af4968e63714c620163714c62a30000',
        //     'brand_name': 'Rock',
        //     'items': [
        //       {
        //         'item_id': '3',
        //         'item_name': '高清全屏',
        //         'item_inventory': 0,
        //         'item_price': '38.00'
        //       }
        //     ]
        //   }
        // ]
      },
      loading: false
    },
    User: {
      ticket: '',
      token: '',
      info: {
        // 'id': '001',
        // 'nickname': 'Vincent',
        // 'avatar': 'http://img.zcool.cn/community/0150d4570b3f2e6ac7251f05f9c6c4.png@1280w_1l_2o_100sh.png',
        // 'balance': '50.00'
      },
      loading: false,
      err: ''
    },
    SaleOrder: {
      data: [
        // {
        //   'order_num': '20180511000001',
        //   'order_date': '2018-05-11 10:00:00',
        //   'order_status': 'SETTLED',
        //   'order_price': '18.00',
        //   'item_type': '高透膜',
        //   'device_name': 'iPhone X',
        //   'device_thumb': 'https://img13.360buyimg.com/n7/jfs/t10675/253/1344769770/66891/92d54ca4/59df2e7fN86c99a27.jpg'
        // }, {
        //   'order_num': '20180511000001',
        //   'order_date': '2018-05-11 10:00:00',
        //   'order_status': 'SETTLED',
        //   'order_price': '18.00',
        //   'item_type': '高透膜',
        //   'device_name': 'iPhone X',
        //   'device_thumb': 'https://img13.360buyimg.com/n7/jfs/t10675/253/1344769770/66891/92d54ca4/59df2e7fN86c99a27.jpg'
        // }
      ],
      status: '',
      coupon_code:'',
      err: {},
      loading: false
    },
    Payment: {
      data: {},
      err: {},
      loading: false
    },
    Coupon: {
      data: {},
      err: {}
    },
    SystemProfiles: {
      data: {},
      err: {}
    },
    Quotation: {
      data: {},
      err: {}
    }
  },
  actions,
  mutations,
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
